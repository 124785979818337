import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/muze-za-nadvahu-konzumace-sacharidu.jpg'
import styles from './post-grid.module.css'

const JakymSportemNejrychlejiZhubnu = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Může za nadváhu konzumace sacharidů? | NutritionPro'}
        description={
          'Sacharidy jsou jednou ze tří hlavních živin. Množství energie v 1 gramu jsou 4 kcal (17 kJ). Slouží především jako zdroj energie. Některé naše orgány jsou schopny jako zdroj energie využít pouze glukózu (monosacharid). Sacharidy slouží také jako zásobárna energie a jsou důležité pro zdraví našich střev. Měly by tvořit více než 50 % našeho jídelníčku. Je důležité rozlišovat kvalitu a vlastnosti sacharidů. Můžeme je dělit na jednoduché a komplexní, ale také na mono, oligo a polysacharidy.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/muze-za-nadvahu-konzumace-sacharidu">
            Může za nadváhu konzumace sacharidů?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Může za nadváhu konzumace sacharidů?"
              date="29.03.2023"
            />
            <div>
              <h5 className={styles.postTitle}>Krátce o sacharidech</h5>
              <p className={styles.postText}>
                Sacharidy jsou jednou ze tří <b>hlavních živin.</b> Množství
                energie v 1 gramu jsou 4 kcal (17 kJ). Slouží především jako{' '}
                <b>zdroj energie.</b> Některé naše orgány jsou schopny jako
                zdroj energie využít pouze glukózu (monosacharid). Sacharidy
                slouží také jako <b>zásobárna energie</b> a jsou důležité pro{' '}
                <b>zdraví našich střev.</b> Měly by tvořit <b>více než 50 %</b>{' '}
                našeho jídelníčku. Je důležité rozlišovat kvalitu a vlastnosti
                sacharidů. Můžeme je dělit na jednoduché a komplexní, ale také
                na mono, oligo a polysacharidy.
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="sportovní dívka"
              />

              <h5 className={styles.postTitle}></h5>
              <p className={styles.postText}>
                Nejsou sacharidy jako sacharidy. Je velký rozdíl, pokud si dáte
                ke svačině čokoládu a sladkou limonádu nebo celozrnné pečivo.
                Proto musíme rozlišovat jednoduché a komplexní sacharidy.
                Jednoduché sacharidy se rychle vstřebávají do těla a vůli tomu
                se <b>rychle zvýší hladina krevního cukru,</b> což je pro nás
                nežádoucí. Mají vysokou energetickou hodnotu a mnohdy minimum
                vitaminů a minerálních látek. Jejich příjem by měl být okolo 17
                % ze všech přijatých sacharidů. Naproti tomu komplexní sacharidy
                obsahují jak <b>vitaminy,</b> tak <b>minerální látky,</b>{' '}
                hladinu krevního cukru zvyšují <b>pozvolna</b>, díky čemuž jsme
                déle sytí a taky <b>obsahují vlákninu,</b> která by v našem
                jídelníčku neměla chybět.
              </p>

              <h5 className={styles.postTitle}>
                Jaké potraviny jsou bohaté na sacharidy?
              </h5>
              <p className={styles.postText}>
                Jednoduché sacharidy můžeme najít například v různých{' '}
                <b>sušenkách, limonádách a čokoládě,</b> ale také v{' '}
                <b>mléce, medu a ovoci.</b> Komplexní sacharidy se vyskytují v{' '}
                <b>pečivu, těstovinách, rýži nebo zelenině.</b> Specifickou
                skupinou sacharidů je <b>vláknina</b>, která tvoří
                nestravitelnou část potravy. Vyskytuje se například v
                luštěninách, ovoci, zelenině, celozrnném pečivu nebo ořeších.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Víte, jak se dostat do formy díky zdravému
                stravování?{' '}
                <a
                  href="https://nutritionpro.cz/blog/dostante-se-do-formy/"
                  target="_blank"
                >
                  Přečtěte si zde.
                </a>
              </p>

              <h5 className={styles.postTitle}>Sacharidy a hubnutí</h5>
              <p className={styles.postText}>
                Už jste někdy od někoho slyšeli, že přestali jíst například
                pečivo a <b>zhubli</b>? Nebo máte obdobnou zkušenost i vy?
                Přesvědčilo vás to o tom, že díky vynechávání sacharidů budete
                hubnout? Na vině však nejsou sacharidy. Jde o starý dobrý{' '}
                <b>kalorický deficit!</b> Tím, že začnete vynechávat sacharidy,
                akorát <b>snížíte energetický příjem,</b> tím pádem se snáz
                dostanete do kalorického deficitu a budete hubnout.{' '}
                <b>Toho lze však docílit i bez vynechávání sacharidů.</b> Jak už
                jsme si řekli, sacharidy jsou důležitým zdrojem energie.
                Například náš mozek a červené krvinky přijímají energii pouze z
                glukózy. Je proto nutné za den přijmout alespoň{' '}
                <b>150 gramů sacharidů</b>, což je hranice pro správnou funkci
                našeho mozku. Vláknina je též velmi důležitou součástí našeho
                jídelníčku a za den bychom ji měli zkonzumovat{' '}
                <b>25 - 30 gramů.</b>
              </p>

              <p className={styles.postText}>
                Pokud se snažíte hubnout, určitě byste se rádi zbavili především{' '}
                <b>tuku</b>, nikoliv svalů. Pokud ovšem vyřadíte ze svého
                jídelníčku sacharidy, začnete <b>přicházet o glykogen</b> ve
                svalech, tím pádem i o vodu, která se na něj váže, a můžete tak
                říct sbohem hlavně vašim svalům. Svaly jsou pro hubnutí
                důležité. Jedná se totiž o metabolicky aktivní tkáň a{' '}
                <b>čím více svalů máte, tím více kalorií pálíte</b>, i když
                zrovna nejste fyzicky aktivní.
              </p>

              <h5 className={styles.postTitle}>
                Mohou tedy sacharidy za nadváhu?
              </h5>
              <p className={styles.postText}>
                Sacharidy mají v našem těle velmi důležitou funkci. Napovídá
                tomu i fakt, že jejich příjem by měl tvořit více než 50 % našeho
                celkového energetického příjmu.{' '}
                <b>Není proto žádoucí, abyste je z jídelníčku vyřazovali.</b>{' '}
                Vybírejte však <b>správné zdroje</b> sacharidů a zaměřte se na
                jejich kvalitu. Samotné <b>sacharidy nadváhu nezpůsobí.</b>{' '}
                Pouze <b>nadměrný příjem energie</b>, ke kterému však může
                přispět vysoký příjem jednoduchých sacharidů.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Pokud chcete zhubnout zdravě a snadno, zvolte raději{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  krabičky NutritionPro.
                </a>
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default JakymSportemNejrychlejiZhubnu
